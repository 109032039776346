import { ProjectCard } from "./ProjectCard";
import { Row } from "react-bootstrap";

function ProjectList({ projects }) {
    return (
      <Row>
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            {...project}
            link={`/${index}`} // Link to the project detail page
          />
        ))}
      </Row>
    );
  }

export default ProjectList;