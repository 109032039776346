import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

export const Contact = () => {
  const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const userID = process.env.REACT_APP_EMAILJS_USER_ID;
  const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;

  const formInitialDetails = {
    fullname: '',
    email: '',
    company: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);

  const onRecaptchaChange = (response) => {
    setRecaptchaResponse(response);
  };

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }

  const formatPhoneNumberInput = (input) => {
    const cleanedInput = input.replace(/\D/g, '');

    const formattedInput = cleanedInput.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');

    return formattedInput;
  };

  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!recaptchaResponse) {
      setStatus({ success: false, message: "Please complete reCAPTCHA." });
      return;
    }

    setButtonText('Sending...');

    try {
      const response = await emailjs.sendForm(serviceID, templateID, e.target, userID, recaptchaResponse);
      console.log('Email sent:', response);

      setFormDetails(formInitialDetails);
      setStatus({ success: true, message: 'Message sent successfully' });
    } catch (error) {
      console.error('Error sending email:', error);
      setStatus({ success: false, message: 'Something went wrong, please try again later.' });
    } finally {
      setButtonText('Send');
    }
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us" />
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Get In Touch</h2>
                  <form onSubmit={handleSubmit} data-service={serviceID} data-template={templateID}>
                    <Row>
                      <Col xs={12} sm={6} md={6} className="px-1">
                        <input type="text" className="required-label" name='from_name' value={formDetails.fullname} placeholder="Full Name" required={true} onChange={(e) => onFormUpdate('fullname', e.target.value)} />
                      </Col>
                      <Col xs={12} sm={6} md={6} className="px-1">
                        <input type="email" name='reply_to' value={formDetails.email} placeholder="Email Address" required={true} onChange={(e) => onFormUpdate('email', e.target.value)} />
                      </Col>
                      <Col xs={12} sm={6} md={6} className="px-1">
                        <input
                          type="tel"
                          name="phone"
                          value={formatPhoneNumber(formDetails.phone)} 
                          placeholder="Phone No."
                          onChange={(e) => {
                            const input = e.target.value.substring(0, 10);  
                            onFormUpdate('phone', formatPhoneNumberInput(input));
                          }}
                        />
                      </Col>
                      <Col xs={12} sm={6} md={6} className="px-1">
                        <input type="text" name='company' value={formDetails.company} placeholder="Company Name" onChange={(e) => onFormUpdate('company', e.target.value)} />
                      </Col>
                      <Col xs={12} className="px-1">
                        <textarea rows="6" name='message' value={formDetails.message} placeholder="Message" required={true} onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                      </Col>
                      <Col xs={12} className="px-1">
                        <ReCAPTCHA
                          sitekey={captchaKey}
                          theme="dark"
                          onChange={(response) => onRecaptchaChange(response)} />
                        <button type="submit"><span>{buttonText}</span></button>
                        {status.message && <p className={status.success === false ? "danger" : "success"}>{status.message}</p>}
                      </Col>
                    </Row>
                  </form>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}