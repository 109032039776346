import { Container, Row, Col } from "react-bootstrap";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ProjectDetail from "./ProjectDetail";
import ProjectList from "./ProjectList";
import hip from '../assets/img/hip.png';
import backend from '../assets/img/backend.jpg';
import dazed from '../assets/img/dazed.png';
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Home Improvement Contractors",
      description: "Design & Development",
      imgUrl: hip,
      eventKey: "first",
      details: "React application designed to help users explore and connect with reliable contractors " +
      "for their home improvement projects. The app is built with the Chakra UI framework to ensure a modern" + 
      "and visually appealing user interface, and it utilizes icons to enhance the user experience. " + 
      "The Carousel component is employed to showcase project images beautifully."
    },
    {
      title: "Team Finder API",
      description: "Backend Development",
      imgUrl: backend,
      eventKey: "second",
      details: "Java-based project showcases the power of microservices architecture using Spring framework components," + 
      "including Kafka, Zipkin, Prometheus, Eureka Discovery Server, and API Gateway. Additionally, Keycloak is integrated for secure user" + 
      "authentication, providing JWT tokens for API calls."
    },
    {
      title: "Dazed Text Adventure",
      description: "C# Development",
      imgUrl: dazed,
      eventKey: "third",
      details: "Simple text-based adventure game built using C#." + 
      " Embark on a journey filled with choices and challenges as you navigate through a captivating interactive story."
    }
  ];

  return (
    <BrowserRouter>
      <section className="project" id="projects">
        <Container>
          <Row>
            <Col size={12}>
              <TrackVisibility>
                {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                  <h2>Projects</h2>
                  <Routes> {/* Use Routes instead of Switch */}
                    <Route path="/" element={<ProjectList projects={projects} />} />
                    {projects.map((project, index) => (
                      <Route
                        key={index}
                        path={`/${index}`}
                        element={<ProjectDetail project={project} />} // Pass a single project object, not the array
                      />
                    ))}
                  </Routes>
                </div>}
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
        <img className="background-image-right" src={colorSharp2} alt=""></img>
      </section>
    </BrowserRouter>
  )
}



