import { Link } from 'react-router-dom';

function ProjectDetail({ project }) {
    return (
        <div className="project-details">
            <h3>{project.title}</h3>
            <p>{project.details}</p>
            <Link to="/" className="return-projects-btn">Back to Projects</Link>
        </div>
    );
}

export default ProjectDetail;